import styled from "styled-components/macro";
import { Typography } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import React from "react";

const StyledMainWrapper = styled.div`
  width: 100%;
  margin-top: 150px;
  margin-bottom: 150px;
`;

const StyledBackground = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-around;
  width: 70%;

  @media (max-width: 750px) {
    flex-direction: column;
    width: 90%;

    img {
      margin-top: 0px;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;

  @media (max-width: 750px) {
    width: 90%;
  }
`;

const AboutSection = React.forwardRef((props, ref) => {
  return (
    <StyledMainWrapper ref={ref}>
      <StyledBackground>
        <AnimationOnScroll animateIn="animate__slideInLeft" delay={200}>
          <img
            src="static/img/koopa-green-dancing.png"
            alt="greenKoopaDancing"
            width="350px"
          />
        </AnimationOnScroll>

        <FlexContainer>
          <AnimationOnScroll
            animateIn="animate__bounceInDown"
            initiallyVisible
            animateOnce
          >
            <Typography
              variation="h2"
              color="limegreen"
              sx={{
                fontSize: { sm: "36px", xs: "24px" },
                textShadow: "2px 2px 4px black",
                fontFamily: "Mario",
              }}
              mb={2}
            >
              About
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInRight" delay={200}>
            <Typography
              color="limegreen"
              sx={{
                fontSize: { sm: "18px", xs: "14px" },
                fontFamily: "Mario",
              }}
            >
              Koopa is a fictional character created by Nintendo in the Mario
              franchise. The sad turtle meme was created by a streamer who goes
              by "Jonithanial". We all simply want to be happy, as do Koopa, but
              when things don't turn out well, well... Sad turtle.
            </Typography>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__slideInRight" delay={300}>
            <Typography
              color="limegreen"
              sx={{
                fontSize: { sm: "18px", xs: "14px" },
                fontFamily: "Mario",
              }}
              mt={2}
            >
              Koopa will be launched stealth with no presale, zero taxes. Koopa
              will only come out of his shell with your Koopa-ration.
            </Typography>
          </AnimationOnScroll>
        </FlexContainer>
      </StyledBackground>
    </StyledMainWrapper>
  );
});

export default AboutSection;
