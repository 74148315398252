import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const data = [
  { name: "90% DEX LP", value: 90 },
  { name: "5% CEX", value: 5 },
  { name: "3% Utility", value: 3 },
  { name: "2% Airdrops", value: 2 },
];

const COLORS = ["#00c3ff", "#00ff9d", "#ffbb00", "#ffee05"];

export default class Chart extends PureComponent {
  render() {
    return (
      <div style={{ width: "100%", height: "500px", margin: "auto" }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey="value"
              data={data}
              fill="#8884d8"
              innerRadius={100}
              outerRadius={170}
              paddingAngle={5}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
