import * as React from "react";

import MenuIcon from "@mui/icons-material/Menu";

import {
  Button,
  Typography,
  Toolbar,
  ListItem,
  List,
  ListItemButton,
  IconButton,
  Drawer,
  Divider,
  CssBaseline,
  Box,
  AppBar,
} from "@mui/material";

const drawerWidth = 240;
const navItems = ["About", "How to Buy", "Tokenomics"];

export default function NavBar({ scrollToSection }) {
  const [activeTab, setActiveTab] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [colorChange, setColorchange] = React.useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 200) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", background: "limegreen" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src={"static/img/brand-logo.png"}
          height="75px"
          width="75px"
          style={{ borderRadius: "100px", border: "solid 3px gold" }}
          alt="brandLogo"
        />
      </Typography>
      <Divider />

      <a
        href="https://app.uniswap.org/#/swap?outputCurrency=0xbf569a50235056b32994c1370f42a76b62ae6acf&amp;inputCurrency=ETH"
        target={"_blank"}
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Button
          sx={{
            color: "#fff",
            fontFamily: "Mario",
            fontSize: "18px",
            textShadow: "2px 2px 4px black",
            border: "solid 3px white",
            borderRadius: "25px",
            paddingRight: 2,
            paddingLeft: 2,
            background: "rgb(0,197,255)",
            zIndex: 100,
            "&:hover": {
              background: "#1280a1",
            },
            mt: 5,
          }}
        >
          Buy Now
        </Button>
      </a>
      <List sx={{ background: "limegreen", mt: 5 }}>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => scrollToSection(item.toLowerCase())}
            >
              <Typography
                color="white"
                sx={{
                  fontFamily: "Mario",
                  fontSize: "14px",
                  textShadow: "2px 2px 4px black",
                  textAlign: "center",
                  width: "100%",
                  "&:hover": {
                    color: "gold",
                    fontSize: "16px",
                  },
                }}
              >
                {item}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          background: colorChange ? "limegreen" : "transparent",
          boxShadow: colorChange ? "2px 2px 4px black" : "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                sm: "flex",
                fontFamily: "Mario",
                alignItems: "center",
                fontSize: "32px",
                textShadow: "2px 2px 4px black",
              },
            }}
          >
            <img
              src={"static/img/brand-logo.png"}
              height="75px"
              width="75px"
              alt="brandLogo"
              style={{ borderRadius: "100px", border: "solid 3px gold" }}
            />
            Koopa
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item}
                onMouseEnter={() => setActiveTab(item.toLowerCase())}
                onMouseLeave={() => setActiveTab(null)}
                onClick={() => scrollToSection(item.toLowerCase())}
                sx={{
                  color: "#fff",
                  fontFamily: "Mario",
                  fontSize: "14px",
                  textShadow: "2px 2px 4px black",
                  padding: 5,
                  "&:hover": {
                    color: "gold",
                    fontSize: "16px",
                  },
                }}
              >
                {activeTab === item.toLowerCase() ? (
                  <img
                    src={"static/img/koopa-shell.png"}
                    alt="koopaShellIcon"
                    width="35px"
                    style={{ marginRight: 5 }}
                  />
                ) : null}
                {item}
              </Button>
            ))}
          </Box>
          <a
            href="https://app.uniswap.org/#/swap?outputCurrency=0xbf569a50235056b32994c1370f42a76b62ae6acf&amp;inputCurrency=ETH"
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
                color: "#fff",
                fontFamily: "Mario",
                fontSize: "18px",
                textShadow: "2px 2px 4px black",
                border: "solid 3px white",
                borderRadius: "25px",
                paddingRight: 2,
                paddingLeft: 2,
                background: "rgb(0,197,255)",

                "&:hover": {
                  background: "#1280a1",
                },
              }}
            >
              Buy Now
            </Button>
          </a>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ background: "limegreen" }}>
        <Drawer
          container={undefined}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "limegreen",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
