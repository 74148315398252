import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import HowToBuySection from "./components/HowToBuySection";
import Tokenomics from "./components/Tokenomics";
import DisclaimerSection from "./components/DisclaimerSection";
import React from "react";
const Home = React.forwardRef((props, ref) => {
  const { aboutRef, tokenomicsRef, howToBuyRef } = ref;
  return (
    <div className="Home">
      <HeroSection />
      <AboutSection ref={aboutRef} />
      <Tokenomics ref={tokenomicsRef} />
      <HowToBuySection ref={howToBuyRef} />
      <DisclaimerSection />
    </div>
  );
});

export default Home;
