import React from "react";
import styled from "styled-components/macro";
import { Typography } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";

const StyledMainWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 150px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  padding: 15px;
  border: 3px solid limegreen;
  border-radius: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  position: relative;

  .img-container {
    width: 200px;
  }
  .text-container {
    width: 70%;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    width: 80%;
  }
`;

const CircleNumber = styled.div`
  position: absolute;
  top: -22px;
  left: calc(50% - 22px);
  height: 35px;
  width: 44px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom: 10px solid gold;
  background-color: limegreen;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Mario;
`;

const HowToBuySection = React.forwardRef((props, ref) => {
  return (
    <StyledMainWrapper ref={ref}>
      <StyledBackground>
        <AnimationOnScroll animateIn="animate__bounceInDown" animateOnce={true}>
          <Typography
            variation="h2"
            color="limegreen"
            sx={{
              fontSize: { sm: "36px", xs: "24px" },
              textShadow: "2px 2px 4px black",
              fontFamily: "Mario",
            }}
            mb={2}
          >
            How To Buy
          </Typography>
        </AnimationOnScroll>

        <FlexContainer>
          <CircleNumber>1</CircleNumber>
          <AnimationOnScroll animateIn="animate__slideInLeft">
            <img
              src="static/img/walletIcon.png"
              alt="walletIcon"
              className="img-container"
            />
          </AnimationOnScroll>

          <div className="text-container">
            <AnimationOnScroll animateIn="animate__bounceInDown">
              <Typography
                color="limegreen"
                sx={{
                  fontSize: { sm: "24px", xs: "18px" },
                  fontWeight: 600,
                  fontFamily: "Mario",
                }}
              >
                Create a Wallet
              </Typography>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn">
              {" "}
              <Typography
                color="black"
                sx={{
                  fontSize: { sm: "24px", xs: "18px" },
                  fontFamily: "Montserrat",
                }}
              >
                Install MetaMask, Trust Wallet, or any other suitable crypto
                wallet from the App Store or Google Play. Desktop users can
                install the Chrome extension from MetaMask.io.
              </Typography>
            </AnimationOnScroll>
          </div>
        </FlexContainer>

        <FlexContainer>
          <CircleNumber>2</CircleNumber>
          <AnimationOnScroll animateIn="animate__slideInLeft">
            <img
              src="static/img/eth.png"
              alt="ethIcon"
              className="img-container"
              width=""
            />
          </AnimationOnScroll>

          <div className="text-container">
            <AnimationOnScroll animateIn="animate__bounceInDown">
              <Typography
                color="limegreen"
                sx={{
                  fontSize: { sm: "24px", xs: "18px" },
                  fontWeight: 600,
                  fontFamily: "Mario",
                }}
              >
                Get Some ETH
              </Typography>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeIn">
              <Typography
                color="black"
                sx={{
                  fontSize: { sm: "24px", xs: "18px" },
                  fontFamily: "Montserrat",
                }}
              >
                Have ETH in your wallet to switch to $KOOPA. If you don’t have
                any ETH, you can buy directly on metamask, transfer from another
                wallet, or buy on another exchange and send it to your wallet.
              </Typography>
            </AnimationOnScroll>
          </div>
        </FlexContainer>

        <FlexContainer>
          <CircleNumber>3</CircleNumber>
          <AnimationOnScroll animateIn="animate__slideInLeft">
            <img
              src="static/img/uniswap.png"
              alt="uniswapIcon"
              className="img-container"
            />
          </AnimationOnScroll>

          <div className="text-container">
            <AnimationOnScroll animateIn="animate__bounceInDown">
              <Typography
                color="limegreen"
                sx={{
                  fontSize: { sm: "24px", xs: "18px" },
                  fontWeight: 600,
                  fontFamily: "Mario",
                }}
              >
                Go to Uniswap
              </Typography>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <Typography
                color="black"
                sx={{
                  fontSize: { sm: "24px", xs: "18px" },
                  fontFamily: "Montserrat",
                }}
              >
                Connect to Uniswap. Go to app.uniswap.org in google chrome or on
                the browser inside your Metamask app. Connect your wallet. Paste
                the $KOOPA token address into Uniswap, select KOOPA, and
                confirm. When Metamask prompts you for a wallet signature, sign.
              </Typography>
            </AnimationOnScroll>
          </div>
        </FlexContainer>

        <FlexContainer>
          <CircleNumber>4</CircleNumber>
          <AnimationOnScroll animateIn="animate__slideInLeft">
            <img
              src="static/img/swapIcon.png"
              alt="swapIcon"
              className="img-container"
            />
          </AnimationOnScroll>

          <div className="text-container">
            <AnimationOnScroll animateIn="animate__bounceInDown">
              <Typography
                color="limegreen"
                sx={{
                  fontSize: { sm: "24px", xs: "18px" },
                  fontWeight: 600,
                  fontFamily: "Mario",
                }}
              >
                Switch ETH for $KOOPA
              </Typography>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <Typography
                color="black"
                sx={{
                  fontSize: { sm: "24px", xs: "18px" },
                  fontFamily: "Montserrat",
                }}
              >
                Switch ETH for $KOOPA. We have ZERO taxes so you don’t need to
                worry about buying with a specific slippage, although you may
                need to use slippage during times of market volatility.
              </Typography>
            </AnimationOnScroll>
          </div>
        </FlexContainer>

        <FlexContainer>
          <iframe
            _ngcontent-sas-c3=""
            title="Uniswapv3"
            src="https://app.uniswap.org/#/swap?outputCurrency=0xbf569a50235056b32994c1370f42a76b62ae6acf&amp;inputCurrency=ETH"
            height="535px"
            width="100%"
            style={{
              border: 0,
              margin: "auto",
              borderRadius: "1rem",
              width: "100%",
            }}
          ></iframe>
        </FlexContainer>
      </StyledBackground>
    </StyledMainWrapper>
  );
});

export default HowToBuySection;
