import NavBar from "components/NavBar";
import Home from "pages/Home";
import { useRef } from "react";

function App() {
  const aboutRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const howToBuyRef = useRef(null);

  const scrollToSection = (section) => {
    if (section === "about") {
      aboutRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else if (section === "tokenomics") {
      tokenomicsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      howToBuyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  return (
    <div className="App">
      <NavBar scrollToSection={scrollToSection} />
      <Home ref={{ aboutRef, tokenomicsRef, howToBuyRef }} />
    </div>
  );
}

export default App;
