import styled from "styled-components/macro";
import { Typography } from "@mui/material";
import PieChart from "./PieChart";
import TokenomicsBG from "assets/tokenomics-bg.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import React from "react";

const StyledMainWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
`;

const StyledBackgroundImage = styled.div`
  background-image: url(${TokenomicsBG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 500px;
`;

const LinearGradient = styled.div`
  background: linear-gradient(to bottom right, #48cc7ff6, #baf5abb9);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
`;

const COLORS = ["#00c3ff", "#00ff9d", "#ffbb00", "#ffee05"];

const Box = styled.div`
  width: 25px;
  height: 25px;
  background: ${({ bgColor }) => bgColor};
  border: 1px solid white;
  margin-right: 25px;
  z-index: 10;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  z-index: 10;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 60%;
  margin-top: 150;
  z-index: 10;

  img {
    margin-top: 150px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    width: 90%;

    img {
      margin-top: 0px;
    }
  }
`;

const Tokenomics = React.forwardRef((props, ref) => {
  return (
    <StyledMainWrapper ref={ref}>
      <LinearGradient />
      <StyledBackgroundImage>
        <FlexContainer>
          <div>
            <AnimationOnScroll
              animateIn="animate__bounceInDown"
              animateOnce={true}
              initiallyVisible
            >
              <Typography
                variation="h2"
                color="white"
                sx={{
                  fontSize: { sm: "36px", xs: "24px" },
                  textShadow: "2px 2px 4px black",
                  fontFamily: "Mario",
                }}
                mb={3}
              >
                Tokenomics
              </Typography>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__bounceInDown" delay={100}>
              <Typography
                color="white"
                sx={{
                  fontSize: { sm: "18px", xs: "14px" },
                  textShadow: "2px 2px 4px black",
                  fontFamily: "Mario",
                }}
                mb={3}
              >
                20 Billion Koopa's
              </Typography>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__bounceInDown" delay={200}>
              <Typography
                color="white"
                sx={{
                  fontSize: { sm: "18px", xs: "14px" },
                  textShadow: "2px 2px 4px black",
                  fontFamily: "Mario",
                }}
              >
                <Flex>
                  <Box bgColor={COLORS[0]} />
                  90% to LP
                </Flex>
                <Flex>
                  <Box bgColor={COLORS[1]} />
                  5% CEX
                </Flex>
                <Flex>
                  <Box bgColor={COLORS[2]} />
                  3% Utility TBA
                </Flex>
                <Flex>
                  <Box bgColor={COLORS[3]} />
                  2% Airdrops
                </Flex>
              </Typography>
            </AnimationOnScroll>
          </div>

          <PieChart />
        </FlexContainer>
      </StyledBackgroundImage>
    </StyledMainWrapper>
  );
});

export default Tokenomics;
