import styled from "styled-components/macro";
import CoolBackground from "assets/Home/background.jpg";
import { Typography, Button } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";

const StyledMainWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledBackgroundImage = styled.div`
  background-image: url(${CoolBackground});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center bottom;
  display: flex;
  height: 900px;
  align-items: flex-start;
  justify-content: center;
`;

const RadialOverlay = styled.div`
  background: radial-gradient(150px 40px at 195px bottom, #ffffff, #ffffff);
  height: 900px;
  width: 100%;
  position: absolute;
  z-index: 2;
  opacity: 0.1;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  z-index: 5;
  margin-top: 150px;

  @media (max-width: 750px) {
    flex-direction: column;
    width: 90%;
  }
`;

const StyledOverlay = styled(AnimationOnScroll)`
  padding: 20px;
  width: 100%;
  z-index: 5;
  max-width: 650px;

  @media (max-width: 750px) {
    width: 90%;
  }
`;

const StyledSocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  max-width: 650px;
  min-width: 360px;

  a {
    width: 20%;
    max-width: 70px;
  }

  img {
    width: 100%;
  }
`;

function HeroSection() {
  return (
    <StyledMainWrapper>
      <RadialOverlay />
      <StyledBackgroundImage>
        <FlexContainer>
          <StyledOverlay animateIn="animate__bounceInDown" initiallyVisible>
            <Typography
              variation="h2"
              color="limegreen"
              sx={{
                fontSize: { md: "32px", sm: "24px", xs: "24px" },
                textShadow: "2px 2px 4px black",
                fontFamily: "Mario",
                textAlign: "center",
              }}
            >
              Make Koopa Happy
            </Typography>
            <Typography
              color="white"
              sx={{
                fontSize: { md: "18px", sm: "16px", xs: "14px" },
                textShadow: "2px 2px 4px black",
                fontFamily: "Mario",
                textAlign: "center",
              }}
              mt={2}
              mb={2}
            >
              The only meme coin that you need to see when crypto is not doing
              so well.
            </Typography>
            <Typography
              color="white"
              sx={{
                fontSize: { sm: "18px", xs: "14px" },
                textShadow: "2px 2px 4px black",
                fontFamily: "Arial",
              }}
              textAlign="center"
              mt={2}
              mb={2}
            >
              <b>Token Contract:</b> 0xbf569a50235056b32994c1370f42a76b62ae6acf
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <AnimationOnScroll
                animateIn="animate__bounceInRight"
                delay={500}
                initiallyVisible
              >
                <a
                  href="https://app.uniswap.org/#/swap?outputCurrency=0xbf569a50235056b32994c1370f42a76b62ae6acf&amp;inputCurrency=ETH"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Button
                    sx={{
                      color: "#fff",
                      fontFamily: "Mario",
                      fontSize: "18px",
                      textShadow: "2px 2px 4px black",
                      border: "solid 3px white",
                      borderRadius: "25px",
                      paddingRight: 2,
                      paddingLeft: 2,
                      background: "limegreen",
                      margin: "auto",

                      "&:hover": {
                        background: "green",
                      },
                    }}
                  >
                    Buy Now
                  </Button>
                </a>
              </AnimationOnScroll>
            </div>
          </StyledOverlay>
          <AnimationOnScroll
            animateIn="animate__bounceInUp"
            delay={500}
            initiallyVisible
          >
            <StyledSocialIcons>
              <a
                href="https://twitter.com/koopacoineth"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src="static/img/socials/twitter.png" alt="twitterIcon" />
              </a>
              <a
                href="https://discord.gg/vbwsrqVB"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src="static/img/socials/discord.png" alt="discordIcon" />
              </a>
              <a
                href="https://etherscan.io/token/0xbf569a50235056b32994c1370f42a76b62ae6acf"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src="static/img/socials/etherscan.png"
                  alt="etherscanIcon"
                />
              </a>
              <a
                href="https://www.dextools.io/app/en/pairs"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src="static/img/socials/dextools.png" alt="dexToolsIcon" />
              </a>
            </StyledSocialIcons>
          </AnimationOnScroll>
        </FlexContainer>
      </StyledBackgroundImage>
    </StyledMainWrapper>
  );
}

export default HeroSection;
