import styled from "styled-components/macro";
import { Typography } from "@mui/material";
import CoolBackground from "assets/Home/footerBackground.png";
const StyledMainWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  height: 640px;
  position: relative;
`;

const StyledBackgroundImage = styled.div`
  background-image: url(${CoolBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0;
  justify-content: flex-end;
  height: 640px;
`;

const RadialOverlay = styled.div`
  background: linear-gradient(
    to bottom right,
    #48cc7f,
    #48cc7fe1,
    #82ee67a9,
    #82ee67e4,
    #82ee67
  );
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  opacity: 1;
`;

const ResponsiveFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  width: 90%;
  padding-bottom: 20px;

  @media (max-width: 750px) {
    flex-direction: column;
    width: 90%;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;

  a {
    margin: 15px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 60%;
  margin-top: 150;
  z-index: 100;

  @media (max-width: 750px) {
    flex-direction: column;
    width: 90%;
  }
`;

function DisclaimerSection() {
  return (
    <StyledMainWrapper>
      <RadialOverlay />
      <StyledBackgroundImage>
        <FlexContainer>
          <Typography
            variation="h2"
            color="white"
            sx={{
              fontSize: { sm: "36px", xs: "24px" },
              textShadow: "2px 2px 4px black",
              fontFamily: "Mario",
              display: "flex",
              alignItems: "center",
            }}
            mb={3}
            mt={15}
          >
            <img
              src="static/img/koopa-shell.png"
              alt="koopaShell"
              width="50px"
              style={{ margin: 10 }}
            />{" "}
            Disclaimer
            <img
              src="static/img/koopa-shell.png"
              alt="koopaShell"
              width="50px"
              style={{ margin: 10 }}
            />{" "}
          </Typography>
          <Typography
            color="white"
            sx={{
              fontSize: { sm: "18px", xs: "14px" },
              textShadow: "2px 2px 4px black",
              fontFamily: "Mario",
            }}
            textAlign="center"
          >
            $KOOPA has no association with Nintendo or Super Mario Bros. This
            token is simply paying homage to a Koopa meme.
            <br />
            <br />
            $KOOPA is a meme coin with no intrinsic value or expectation of
            financial return. There is no formal team or roadmap. the coin is
            completely useless and for entertainment purposes only.
          </Typography>
          <a href="mailto:info@koopa.vip" style={{ color: "#27afd8" }}>
            <Typography
              color="#fbff00"
              sx={{
                fontSize: { sm: "18px", xs: "14px" },
                textShadow: "2px 2px 4px black",
                fontFamily: "Mario",
              }}
              textAlign="center"
              mt={10}
            >
              Contact: info@koopa.vip
            </Typography>
          </a>
        </FlexContainer>

        <ResponsiveFlex>
          <Flex>
            <Typography
              variant="h6"
              component="div"
              color="white"
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  sm: "flex",
                  fontFamily: "Mario",
                  alignItems: "center",
                  fontSize: "32px",
                  textShadow: "2px 2px 4px black",
                },
              }}
            >
              <img
                src={"static/img/brand-logo.png"}
                style={{ borderRadius: "100px", border: "solid 3px gold" }}
                height="75px"
                width="75px"
                alt="brandLogo"
              />
              Koopa
            </Typography>
          </Flex>

          <Flex style={{ marginTop: 25 }}>
            <a
              href="https://twitter.com/koopacoineth"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="static/img/socials/twitter.png"
                alt="dexTools"
                width="44px"
              />
            </a>
            <a
              href="https://discord.gg/vbwsrqVB"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="static/img/socials/discord.png"
                alt="dexTools"
                width="44px"
              />
            </a>
            <a
              href="https://etherscan.io/token/0xbf569a50235056b32994c1370f42a76b62ae6acf"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="static/img/socials/etherscan.png"
                alt="dexTools"
                width="44px"
              />
            </a>
            <a
              href="https://dextools.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="static/img/socials/dextools.png"
                alt="dexTools"
                width="44px"
              />
            </a>
          </Flex>
        </ResponsiveFlex>
      </StyledBackgroundImage>
    </StyledMainWrapper>
  );
}

export default DisclaimerSection;
